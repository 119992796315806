<template>
  <div id="collect-data-slide-component">
    <slide-heading
      :heading="slide.heading"
      :tool-tip="slide.tool_tip"
    />

    <slide-image-and-description :slide="slide" />

    <ct-centered-spinner v-if="saving">
      Saving...
    </ct-centered-spinner>

    <div v-else>
      <schema-form
        v-if="slideHasSchemaFields"
        :ref="`slide-form-${ slide.id }`"
        :key="renderKey"
        v-model="formValues"
        class="mt-4"
        :fields="slide.schema.fields"
        :suggestion-fields="suggestionFields"
        :contextual-jurisdiction="selectedJurisdiction"
        @ra-signup="raSignUp"
        @show-contact-modal="showContactModal"
        @suggestion-toggled="suggestionToggled($event)"
      />

      <div class="buttons mt-3 mb-0 d-flex flex-column">
        <div class="slide-viewer-button-row mt-3 mb-0 ">
          <b-button
            v-if="skipEnabled"
            variant="default"
            aria-label="skip button"
            @click="$emit('next-slide')"
          >
            Skip
          </b-button>

          <b-button
            class="next-button"
            variant="primary"
            :disabled="!formDataValid"
            aria-label="continue button"
            @click="saveData"
          >
            Continue
          </b-button>
        </div>
      </div>

      <contact-modal
        ref="contact-modal"
        :title="'Add a contact'"
        :type="'add'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import { fillFormFromDetails } from '@/components/SchemaForm/helper.js'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { formatManagementTypeAndOfficals } from '../../../SchemaForm/helper'

export default {
  name: 'CollectDataSlide',
  components: {
    SchemaForm:        () => import('@/components/SchemaForm/SchemaForm'),
    SlideHeading:      () => import('@/components/StagelineV2/shared/SlideHeading'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ContactModal:      () => import('@/components/ContactModal'),
    SlideImageAndDescription: () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
  },
  mixins: [makeToastMixin],
  props: {
    slide: Object,
    selectedJurisdiction: Object,
  },
  data() {
    return {
      renderKey: 0,
      formValues: {},
      suggestionFields: [],
      saving: false,
      multipleKeys: [],
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
    ]),
    slideHasSchemaFields() {
      return this.slide?.schema?.fields
    },
    formDataValid() {
      return this.validateFormValues()
    },
    skipEnabled() {
      return this.slide?.config?.skip_enabled
    },
  },
  mounted() {
    if (this.slide?.schema) this.loadSchemaFields()
  },
  methods: {
    ...mapActions('stageline', [
      'goToNextSlide',
    ]),
    ...mapActions('companies', [
      'saveStagelineCompanyData',
    ]),
    ...mapActions('checkout', [
      'addToCart',
      'fetchProduct',
    ]),
    loadSchemaFields() {
      this.multipleKeys = this.slide.schema.fields.filter(f => 'can_have_multiple' in f && f['can_have_multiple'] === true).map(f => f.name)
      this.fillFormFromCompanyDetails()
    },
    fillFormFromCompanyDetails() {
      const [formValues, suggestionFields] = fillFormFromDetails(this.slide.schema.fields, this.company.details)
      if (!_.isEmpty(formValues)) this.formValues = formValues
      if (Object.keys(suggestionFields).length) this.suggestionFields = suggestionFields
    },
    async saveData() {
      const form = this.$refs[`slide-form-${this.slide.id}`].form

      if (!form.validate()) {
        this.errorToast('Error', 'Please fill out the rest of the required information.')
      } else {
        this.saving = true

        this.formValues = formatManagementTypeAndOfficals(this.multipleKeys, this.formValues)

        const response = await this.saveStagelineCompanyData({
          companyId: this.company.id,
          jurisdictionId: this.selectedJurisdiction.id,
          details: this.formValues,
        })

        if (response.success) {
          this.$emit('next-slide')
          this.saving = false
        } else {
          this.errorToast('Error', 'Error updating company data.')
        }
      }
    },
    validateFormValues() {
      for (let key in this.formValues) {
        if (this.formValues.hasOwnProperty(key) &&
          this.formValues[key] !== undefined &&
          this.formValues[key] !== ""
        ) {
          return true
        }
      }
      return false
    },
    async raSignUp() {
      let raProduct = await this.fetchProduct({
        productKind: 'registered_agent_product',
        jurisdictionId: this.selectedJurisdiction.id,
      })
      if (raProduct.length) {
        raProduct = raProduct[0]
        await this.addToCart( { ...raProduct, skipResolveOptionalItems: true } )
      }
    },
    showContactModal() {
      this.$refs['contact-modal'].show()
    },
    suggestionToggled(event) {
      if (event.field.meta && event.field.meta.type === 'address' && !event.usingSuggestion) {
        delete this.suggestionFields[event.field.name]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#collect-data-slide-component {
  .buttons {
    .slide-viewer-button-row {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-end;
      button {
        margin-left: 0.9375em;
      }
    }
  }
}
</style>
