var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "collect-data-slide-component" } },
    [
      _c("slide-heading", {
        attrs: { heading: _vm.slide.heading, "tool-tip": _vm.slide.tool_tip },
      }),
      _c("slide-image-and-description", { attrs: { slide: _vm.slide } }),
      _vm.saving
        ? _c("ct-centered-spinner", [_vm._v("\n    Saving...\n  ")])
        : _c(
            "div",
            [
              _vm.slideHasSchemaFields
                ? _c("schema-form", {
                    key: _vm.renderKey,
                    ref: `slide-form-${_vm.slide.id}`,
                    staticClass: "mt-4",
                    attrs: {
                      fields: _vm.slide.schema.fields,
                      "suggestion-fields": _vm.suggestionFields,
                      "contextual-jurisdiction": _vm.selectedJurisdiction,
                    },
                    on: {
                      "ra-signup": _vm.raSignUp,
                      "show-contact-modal": _vm.showContactModal,
                      "suggestion-toggled": function ($event) {
                        return _vm.suggestionToggled($event)
                      },
                    },
                    model: {
                      value: _vm.formValues,
                      callback: function ($$v) {
                        _vm.formValues = $$v
                      },
                      expression: "formValues",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "buttons mt-3 mb-0 d-flex flex-column" },
                [
                  _c(
                    "div",
                    { staticClass: "slide-viewer-button-row mt-3 mb-0" },
                    [
                      _vm.skipEnabled
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "default",
                                "aria-label": "skip button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("next-slide")
                                },
                              },
                            },
                            [_vm._v("\n          Skip\n        ")]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "next-button",
                          attrs: {
                            variant: "primary",
                            disabled: !_vm.formDataValid,
                            "aria-label": "continue button",
                          },
                          on: { click: _vm.saveData },
                        },
                        [_vm._v("\n          Continue\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("contact-modal", {
                ref: "contact-modal",
                attrs: { title: "Add a contact", type: "add" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }